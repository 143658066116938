<template>
	<div>
		<div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap" >
			<div class="d-flex align-items-center flex-wrap mr-1">

			</div>
			<div class="d-flex align-items-center">
				<b-button variant="success" @click="openAddProduct();" style="margin: 0 10px 0 0px;width: 130px;"><i class="la la-plus"></i>{{$t('PRODUCT.MSG_ADD')}}</b-button>
				<b-button v-if="this.disableDraggable" variant="primary" @click="DisableDraggable();" style="width: 130px;"><i class="la la-list"></i>{{$t('PRODUCT.MSG_REORGANIZE')}}</b-button>
				<b-button v-if="!this.disableDraggable" variant="danger" @click="SubmitDraggable();" style="width: 130px;"><i class="la la-save"></i>{{$t('PRODUCT.MSG_SAVE')}}</b-button>
			</div>
			
		</div>
		<div class="row">
			<div class="col-md-12">
						<div v-if="hasProduct">
							<v-expansion-panels v-model="panel" :disabled="disabled" multiple>
								<v-expansion-panel v-for="(CatPro, i) in categoryList" :key="i">
									<v-expansion-panel-header>
										
										        <v-row align="center" class="spacer" no-gutters>
													<v-col class="hidden-xs-only" sm="1" md="1">
														<i style="color:#1BC5BD" v-if="CatPro.status" class="la la-eye"></i>
														<i style="color:#F64E60" v-else class="la la-eye-slash"></i>
													</v-col>
													<v-col class="hidden-xs-only" sm="9" md="9">
														<strong v-html="CatPro.name"></strong>
													</v-col>

													<v-col class="text-no-wrap" cols="1" sm="1">
														<b-badge variant="primary" pill>{{categoryProduct[CatPro.id_category_product].length}}</b-badge>
													</v-col>

												</v-row>
									</v-expansion-panel-header>
										
									<v-expansion-panel-content>
									
										<draggable  group="klik" v-model="categoryProduct[CatPro.id_category_product]"  item-key="name" :options="{disabled : disableDraggable, animation: 120}" @end="onEnd">
											<div v-for="(product, j) in categoryProduct[CatPro.id_category_product]" :key="j" class="row" >
												<div class="col-md-2 col-3" >
													<div class="images-wrapper">
														<img style="max-width: 100%;" :src="imgUrl+product.image" class="image">
													</div>
												</div>
												<div class="col-md-9 col-7" >
													<h3 class="Title700 f8">{{ product.name}}</h3>
													<b>{{CurrencyFormat(product.price)}}</b>
												</div>
												<div class="col-md-1 col-2" >
													<b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
														<template v-slot:button-content>
														  <i class="ki ki-bold-more-ver"></i>
														</template>
														<!--begin::Navigation-->
														<div class="navi navi-hover min-w-md-250px">
																<b-dropdown-text tag="div" class="navi-item">
																	<!--<label class="switch switch-icon">
																		<input ref="ref_checkbox_category" type="checkbox" @change="StatusProduct(product.id_product,product.status);" :checked="product.status"/>
																		<span v-if="product.status">Afficher</span>
																		<span v-else>Masque</span>
																	</label>-->
																	<a href="#product" class="navi-link" v-if="product.status == true" @click="StatusProduct(product.id_product,product.status);">
																		<span class="navi-icon" >
																			<i style="color:#F64E60" class="la la-eye-slash"></i>
																		</span>
																		<span class="navi-text">{{$t('PRODUCT.MSG_MASK')}}</span>
																	</a>
																	<a href="#product" class="navi-link" v-else @click="StatusProduct(product.id_product,product.status);">
																		<span class="navi-icon" >
																			<i style="color:#1BC5BD" class="la la-eye"></i>
																		</span>
																		<span class="navi-text">{{$t('PRODUCT.MSG_SHOW')}}</span>
																	</a>
																</b-dropdown-text>
																<b-dropdown-text tag="div" class="navi-item">
																	<a href="#product" @click="getProductById(product.id_product)" class="navi-link">
																		<span class="navi-icon">
																			<i class="la la-edit"></i> 
																		</span>
																		<span class="navi-text">{{$t('PRODUCT.MSG_UPDATE')}}</span>
																	</a>
																</b-dropdown-text>
																<!--<b-dropdown-text tag="div" class="navi-item">
																	<a href="#" class="navi-link">
																		<span class="navi-icon">
																			<i class="flaticon2-group"></i>
																		</span>
																		<span class="navi-text">New Group</span>
																	</a>
																</b-dropdown-text>-->
														 
														</div>
													</b-dropdown>
												</div>
												<div class="col-md-12 col-12">
													<span style="font-size: 11px;color: #787878!important;" class="Title400" v-html="product.description"></span>
												</div>
												<hr style="width: 100%;">
											</div>
											<div v-if="categoryProduct[CatPro.id_category_product].length == 0" style="width: 100%;display: block;text-align: center;text-align: center;padding: 17px 0px 0px;font-size: 17px;">
												<i style="font-size: 22px;color: #000;margin: 0px 5px 0 0px;" class="la la-folder-open-o"></i>{{$t('PRODUCT.MSG_EMPTY_CATEGORY')}}
											</div>
											
										</draggable>
										
									</v-expansion-panel-content>
								</v-expansion-panel>

							</v-expansion-panels>
	 					</div>
	 					<div v-else>
							<div class="card card-custom card-stretch gutter-b">
								<div class="card-header border-0 pt-5">
									<h3 class="card-title font-weight-bolder">{{$t('PRODUCT.MSG_FIRST_PRODUCT')}}</h3>
									<div class="card-toolbar">
										<div class="dropdown b-dropdown btn-group">
										</div>
									</div>
								</div>
								<div class="card-body d-flex flex-column">
									<div class="flex-grow-1" style="position: relative;">
										<div style="min-height: 178.7px;">
											<div class="apexcharts-canvas apexchartsr2ayx3sv apexcharts-theme-light" style="text-align:center">
												<iframe :src="$t('MSG_FIRST_PRODUCT_URL')" frameborder="0" allowfullscreen></iframe>
											</div>
										</div>
									</div>
									<div class="pt-1"  style="text-align: center;">
										<a href="#" class="btn btn-success btn-shadow-hover font-weight-bolder w-80 py-3" @click="openAddProduct();"><i class="la la-plus"></i>{{$t('PRODUCT.MSG_FIRST_PRODUCT_ADD')}}</a>
									</div>
								</div>
							</div>
	 					</div>
			</div> 

		</div> 
		<div data-app>
			<v-layout row justify-center>
				<v-dialog v-model="AddProduct" fullscreen hide-overlay transition="dialog-bottom-transition">
					<v-card >
						<v-toolbar flat dark color="primary">
							<v-btn icon dark @click="AddProduct = false">
								<i class="la la-close"></i>
							</v-btn>
							<v-toolbar-title>{{$t('PRODUCT.MSG_PRODUCT')}}</v-toolbar-title>
							<v-spacer></v-spacer>
							<v-toolbar-items>
								<v-btn success  @click="submitProduct();">{{$t('PRODUCT.MSG_SEND')}}</v-btn>
							</v-toolbar-items>
						</v-toolbar>
						<v-list three-line subheader>
							<b-card class="main-card mb-3">
								<v-layout row wrap>
									<b-row class="col-md-12">
										<label for="input-valid">{{$t('PRODUCT.MSG_PRODUCT_NAME')}}</label>
										<b-form-input type="text" v-bind:value="name" v-on:change="name = $event"></b-form-input>
									</b-row>
								</v-layout>
								<v-layout row wrap>
									<b-row class="col-md-12">
										<label for="input-valid" style="width: 100%;">{{$t('PRODUCT.MSG_PRODUCT_DESC')}}</label>
										<!--<b-form-textarea v-bind:value="description" v-on:change="description = $event" placeholder="" rows="3" max-rows="6" ></b-form-textarea>-->
										<br>
										<p>
											<vue-editor v-model="description" rows="3" max-rows="6"></vue-editor>
										</p>
									</b-row>
								</v-layout>
								<v-layout row wrap>
									<b-row class="col-md-12">
										<label for="input-valid">{{$t('PRODUCT.MSG_PRODUCT_PRICE')}}</label>
										<b-form-input type="text" v-bind:value="price" v-on:change="price = $event"></b-form-input>
									</b-row>
								</v-layout>
								<div class="form-group row">
									<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('PRODUCT.MSG_PRODUCT_PIC')}}</label>
									<div class="col-lg-9 col-xl-9">
										<div class="image-input image-input-outline" id="kt_profile_avatar">
											<div class="image-input-wrapper" :style="{ backgroundImage: `url(${Product_Pic})` }"></div>
											<label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
												<i class="fa fa-pen icon-sm text-muted"></i>
												<input type="file" name="ImgProduct" id="ImgProduct" accept=".png, .jpg, .jpeg" @change="onFileChange($event)"/>
												<input type="hidden" name="profile_avatar_remove" />
											</label>
											<span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
												<i class="ki ki-bold-close icon-xs text-muted"></i>
											</span>
											<span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove" data-toggle="tooltip" title="Remove avatar" @click="Product_Pic = null">
												<i class="ki ki-bold-close icon-xs text-muted"></i>
											</span>
										</div>
										<span class="form-text text-muted">{{$t('PRODUCT.MSG_PRODUCT_FILE_TYPE')}}</span>
									</div>
								</div>
							</b-card>
							<!--<b-card class="main-card mb-3">
								<h3>Variantes</h3>
								<div class="row" v-for="(Attribute, i) in Attributes" :key="i">
									<div class="col-lg-5 col-xl-5">
										<b-form-input v-model="Items" placeholder="Enter your name" ></b-form-input>
									</div>
									<div class="col-lg-5 col-xl-5">
										 <b-form-tags
										  input-id="tags-remove-on-delete"
										  :input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }"
										  v-model="value"
										  separator=" "
										  placeholder="Enter new tags separated by space"
										  remove-on-delete
										  no-add-on-enter
										></b-form-tags>
										<b-form-text id="tags-remove-on-delete-help" class="mt-2">
										  Press <kbd>Backspace</kbd> to remove the last tag entered
										</b-form-text>
									</div>
									<div class="col-lg-2 col-xl-2">
									supprimer
									</div>
								</div>
								<div class="row">
									<div class="col-lg-12 col-xl-12">
										<a href="#" class="btn btn-success btn-shadow-hover font-weight-bolder w-80 py-3" @click="AddInputProductAttributes();"><i class="la la-plus"></i>Ajouter 1er Varient</a>
									</div>
								</div>
							</b-card>-->
						</v-list>
					</v-card>
				</v-dialog>
			</v-layout>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import moment from 'moment'; 
import Swal from "sweetalert2";
import draggable from "vuedraggable";
import { VueEditor } from "vue2-editor";

moment.locale("fr");
import { mapGetters } from "vuex";
moment.updateLocale('fr', {
    relativeTime : {
        past: function(input) {
          return input === "à l'instant"
            ? input
            : 'depuis '+input
        },
        s  : "à l'instant",
        future: "dans %s",
        ss : '%d secondes',
        m:  "une minute",
        mm: "%d minutes",
        h:  "une heure",
        hh: "%d heures",
        d:  "un jour",
        dd: "%d jours",
        M:  "un mois",
        MM: "%d mois",
        y:  "une année",
        yy: "%d années"
    }
});
export default {
	components: {
		draggable,
		VueEditor
	  },
	data: () => ({
		Attributes: [],
		default_photo: "media/klik/no-product.png",
		Product_Pic: "media/klik/no-product.png",
		hasProduct: true,
		Product_Pic_Update: false,
		description : "",
		price : "",
		panel: [],
		disabled: false,
		readonly: false,
		name : "",
		color : "",
		AddProduct : false,
		HasUpdateProduct : false,
		switch1: false,
		disableDraggable : true,
		fields: [
			{ key: 'status', label: '', sortable: false, 'class': 'text-center' },
			{ key: 'name', label: 'Catégorie', sortable: true, 'class': 'text-center' }
		],
		totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 20, 30],
        imgUrl: '',
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        items: [],
        categoryProduct: [],
        categoryList: [],
        ProductList: [],
		hasUpdate : false,
	}),
	async mounted() {
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = process.env.VUE_APP_APP_API;
		this.imgUrl = process.env.VUE_APP_APP_API+'/klik/product/img/';
		await this.getCategory();
		this.fields = [
			{ key: 'status', label: '', sortable: false, 'class': 'text-center' },
			{ key: 'name', label: this.$t("PRODUCT.MSG_CATEGORY"), sortable: true, 'class': 'text-center' }
		];
	},
	computed: {
		...mapGetters(["currentUserAccountInfo"]),
		...mapGetters(["currentUserPersonalInfo"]),
		...mapGetters(["numberFormat"])
	},
	methods: {
		AddInputProductAttributes() {
			this.Attributes.push({});
		},
		CurrencyFormat(number) {
			return this.numberFormat(number);
		},
		photo() {
			console.log(this.Product_Pic);
			return this.Product_Pic == "" ? this.default_photo : this.Product_Pic;
		},
		onFileChange(e) {
			this.Product_Pic_Update = true;
			const file = e.target.files[0];

			if (typeof FileReader === "function") {
				const reader = new FileReader();
				reader.onload = event => {
					this.Product_Pic = event.target.result;
				};
				reader.readAsDataURL(file);
			}else {
				alert("Sorry, FileReader API not supported");
			}
			this.photo();
		},
		submitPicture() {
			
			var ext = ['jpeg',"jpg","png",'JPEG',"JPG","PNG"];

			var formData = new FormData();
			const imagefile = document.querySelector('#profile_avatar');
			for (var x = 0; x < imagefile.files.length; x++) {
				var nameFileExt = imagefile.files[x].name.split(".");
				nameFileExt = nameFileExt[nameFileExt.length-1];
				if(ext.indexOf(nameFileExt) == -1){
					alert(this.$t("PRODUCT.MSG_ERROR_READ_FILE"));
					return;
				}
				formData.append("file", imagefile.files[x], imagefile.files[x].name);
			}
			//formData.append("id_customer",this.currentUserPersonalInfo.id_customer);
			axios.post("/klik/customer/img",formData, {
																					headers: {
																					  'Content-Type': 'multipart/form-data'
																					}})
			.then((response) => {
				this.Product_Pic_Update = false;
				console.log(response);
				
			}).catch((errors) => {
				console.log(errors)  ;
			});
			
		},
		onEnd(event) {
			console.log(event);
			console.log(this.categoryProduct);
		},
		openAddProduct() {
			this.Product_Pic = "media/klik/no-product.png";
			this.HasUpdateProduct = false;
			this.AddProduct = true;
			this.name = "";
			this.description = "";
			this.price = "";
			this.hasUpdate = false;
			this.Product_Pic_Update = false;
		},
		DisableDraggable() {
			this.disableDraggable = !this.disableDraggable;
		},
		SubmitDraggable() {
			this.disableDraggable = !this.disableDraggable;
			var arraySubmit = [];
			for(var i in this.categoryProduct){
				for(var j in this.categoryProduct[i]){
					arraySubmit.push({id_category : i , index : j, id_product:this.categoryProduct[i][j].id_product});
				}
			}
			console.log(arraySubmit);
			var data = {index:arraySubmit};
			axios.post("/klik/product/category/index",data).then((response) => {
				this.getCategory(response);
			}).catch((errors) => {    
				console.log(errors)  ;
				localStorage.clear();				
				this.$router.push('/login');
			});
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		async getCategory() {
			axios.post("/klik/category/get").then((response) => {
				let str = [];
				str.push({id_category_product:0,name:"-",status:true});
				let strCategoryProduct = [];
				strCategoryProduct[0] = [];
				Object.keys(response.data.Category).forEach((i) => {
					str.push({id_category_product:response.data.Category[i].id_category_product,name:response.data.Category[i].name,status:response.data.Category[i].visible});
					if(response.data.Category[i].id_category_product == null || response.data.Category[i].id_category_product == 'null'){
						response.data.Category[i].id_category_product = 0;
					}
					strCategoryProduct[response.data.Category[i].id_category_product] = [];
					this.panel.push(i);
				});
				console.log(str);
				this.categoryList = str;
				this.categoryProduct = strCategoryProduct;
				console.log(this.categoryProduct);
				this.getProduct();
			}).catch((errors) => {    
				console.log(errors)  ;
				localStorage.clear();				
				this.$router.push('/login');
			});
		},
		async getProduct() {
			axios.post("/klik/product/get").then((response) => {
				let str = [];
				
				if(response.data.product == false){
					this.hasProduct = false;
					this.ProductList = [];
					this.totalRows = 0;
					return true;
				}
				this.hasProduct = true;
				Object.keys(response.data.product).forEach((i) => {
					str.push({
							description:response.data.product[i].description,
							id_category_product:response.data.product[i].id_category_product,
							id_product:response.data.product[i].id_product,
							image:response.data.product[i].image,
							index:response.data.product[i].index,
							name:response.data.product[i].name,
							price:response.data.product[i].price,
							status:response.data.product[i].status
						});
					if(response.data.product[i].id_category_product == null || response.data.product[i].id_category_product == 'null'){
						response.data.product[i].id_category_product = 0;
					}
					this.categoryProduct[response.data.product[i].id_category_product].push({
							description:response.data.product[i].description,
							id_category_product:response.data.product[i].id_category_product,
							id_product:response.data.product[i].id_product,
							image:response.data.product[i].image,
							index:response.data.product[i].index,
							name:response.data.product[i].name,
							price:response.data.product[i].price,
							status:response.data.product[i].status
						});;
				});
				this.ProductList = str;
				this.totalRows = str.length;
			}).catch((errors) => {    
				console.log(errors)  ;
				localStorage.clear();				
				this.$router.push('/login');
			});
		},
		async getProductById(id_product) {
			this.hasUpdate = id_product;
			var data = {id_product:id_product};
			axios.post("/klik/product/getbyid",data).then((response) => {
				this.name = response.data.product.name;
				this.description  = response.data.product.description;
				this.price = response.data.product.price;
				this.Product_Pic = response.data.product.image;
				this.Product_Pic = this.imgUrl+this.Product_Pic;
				this.AddProduct = true;
				this.Product_Pic_Update = false;
				this.hasUpdate = id_product;
			}).catch((errors) => {    
				console.log(errors)  ;
				localStorage.clear();				
				this.$router.push('/login');
			});
		},
		submitProduct() {
			//var name = this.AddProduct = true;
			var name = this.name;
			var description = this.description ;
			var price = this.price;
			
			
			if(name == ""){
				Swal.fire({
					title:this.$t("APP_NAME"),
					text: this.$t("PRODUCT.MSG_ERROR_EMPTY_NAME"),
					icon: 'warning',
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					confirmButtonText: "Ok",
				});
				return false;
			}
			if(description == ""){
				Swal.fire({
					title:this.$t("APP_NAME"),
					text: this.$t("PRODUCT.MSG_ERROR_EMPTY_DESC"),
					icon: 'warning',
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					confirmButtonText: "Ok",
				});
				return false;
			}
			if(price == ""){
				Swal.fire({
					title:this.$t("APP_NAME"),
					text: this.$t("PRODUCT.MSG_ERROR_EMPTY_PRICE"),
					icon: 'warning',
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					confirmButtonText: "Ok",
				});
				return false;
			}
			var url = "/klik/product/add";
			var data = {name:name,description:description,price:price,id_category_product:null,image:""};
			if(this.hasUpdate != false){
				data.id_product_update = this.hasUpdate;
				url = "/klik/product/update";
			}
			axios.post(url,data).then((response) => {
				if(this.Product_Pic_Update){
					this.Upload_Picture(response.data.product);
				}else{
					this.getCategory();
				}
				
				this.AddProduct = false;
			}).catch((errors) => {    
				this.AddProduct = false;
				console.log(errors)  ;
				localStorage.clear();;
				this.$router.push('/login');
			});
		},
		Upload_Picture(id_product) {
			var ext = ['jpeg',"jpg","png",'JPEG',"JPG","PNG"];
			var formData = new FormData();
			const imagefile = document.querySelector('#ImgProduct');
			//for (var x = 0; x < imagefile.files.length; x++) {
			var x = 0;
			var nameFileExt = imagefile.files[x].name.split(".");
			nameFileExt = nameFileExt[nameFileExt.length-1];
			if(ext.indexOf(nameFileExt) == -1){
				alert(this.$t("PRODUCT.MSG_ERROR_READ_FILE"));
				return;
			}
			formData.append("file", imagefile.files[x], imagefile.files[x].name);
			formData.append("id_product",id_product);
			this.Product_Pic_Update = false;
			axios.post("/klik/product/img",formData, {
										headers: {
										  'Content-Type': 'multipart/form-data'
										}})
			.then((response) => {
				this.getCategory(response);
				
			}).catch((errors) => {
				console.log(errors);
			});
		},
		async StatusProduct(id_product,status) {
			Swal.fire({
					title: this.$t("PRODUCT.MSG_ALERT_STATUS_TITLE"),
					text: this.$t("PRODUCT.MSG_ALERT_STATUS_TEXT"),
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: "Ok",
					denyButtonText: "Annuler",
			}).then(async (result) => {
				this.items = [];
				if (result.isConfirmed) {
					status = (status != true ? "t" : "f");
					var data = {id_product:id_product,status:status};
					axios.post("/klik/product/status/change",data).then(async (response) => {
						await this.getCategory(response);
					}).catch((errors) => {    
						this.AddProduct = false;
						console.log(errors)   ;
						localStorage.clear();
						this.$router.push('/login');
					});
					
					//return;
				}else{
					Swal.fire(this.$t("APP_NAME"), this.$t("PRODUCT.MSG_ALERT_STATUS_TEXT"), 'info')
					
				}
			}) 
		},
		
	},
}
</script>
